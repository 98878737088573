import React from "react";
import {
  Container,
  Grid,
  TextField,
  Button,
  IconButton,
  Typography,
  makeStyles,
  createMuiTheme,
  ThemeProvider,
  Link,
  Hidden,
  Snackbar,
} from "@material-ui/core";
import FacebookIcon from "@material-ui/icons/Facebook";
import YouTubeIcon from "@material-ui/icons/YouTube";
import InstagramIcon from "@material-ui/icons/Instagram";
import CopyrightIcon from "@material-ui/icons/Copyright";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import "./Footer.sass";
//import axios from "axios";
import MuiAlert from "@material-ui/lab/Alert";

const useStyles = makeStyles(() => ({
  icon: {
    color: "#000",
  },
  text: {
    fontFamily: "Poppins",
    color: "#fff",
  },
  bold: {
    fontFamily: "Poppins",
    color: "#fff",
    fontWeight: "bold",
  },
  button: {
    height: "56px",
    color: "#fff",
    textTransform: "capitalize",
    fontFamily: "Poppins",
    borderRadius: 12,
    backgroundColor: "#E83484",
    "&:hover": {
      backgroundColor: "#E83484",
      "@media (hover: none)": {
        backgroundColor: "#E83484",
      },
    },
  },
  form:{
    width: '30%',
    '& .MuiTextField-root': {
      marginBottom:theme.spacing(2)
    },
  },
  form_mobile: {
    width: '100%',
    '& .MuiTextField-root': {
      marginBottom:theme.spacing(2)
    },
  }
}));

const theme = createMuiTheme({
  overrides: {
    MuiOutlinedInput: {
      root: {
        "&$focused $notchedOutline": {
          borderColor: "#002E5D",
        },
        fontFamily: "Poppins",
        borderRadius: 12,
        backgroundColor: "#fff",
      },
    },
    MuiFormLabel: {
      root: {
        "&$focused": {
          color: "#002E5D",
        },
        fontFamily: "Poppins",
      },
    },
  },
});

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

/*function validateEmail(mail) {
  return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    mail
  );
}*/

function Footer() {
  const [state, setState] = React.useState({
    email: "",
    emailError: false,
    curretYear: new Date(),
    openSnackbar: false,
    snackbarMessage: "",
    severity: "error", //success
  });

  const classes = useStyles();

  /*const onSubmit = async () => {
    return new Promise(async (resolve, reject) => {
      let completed = false;

      setTimeout(() => {
        if (!completed) {
          completed = true;
          return reject("");
        }
      }, 15000);

      try {
        let { email } = state;
        let res = await axios.post(
          `https://legacy.mentorme.md/rest-auth/subscribe`,
          {
            name: "Mentor Me",
            email: email,
            subject: "Newsletter",
            body: "",
          },
          { headers: { "Content-Type": "application/json" } }
        );
        if (!completed) {
          completed = true;
          return resolve(res.data);
        }
      } catch (err) {
        if (!completed) {
          completed = true;
          return reject(err);
        }
      }
    });
  };

  const onClick = async () => {
    try {
      let { email } = state;
      if (validateEmail(email)) {
        let result = await onSubmit();
        if (result)
          setState({
            ...state,
            openSnackbar: true,
            snackbarMessage: "Well done!",
            severity: "success",
            emailError: false,
          });
      } else {
        setState({
          ...state,
          openSnackbar: true,
          snackbarMessage: "Email wrong",
          severity: "error",
          emailError: true,
        });
      }
    } catch (err) {
      setState({
        ...state,
        openSnackbar: true,
        snackbarMessage: "Something went wrong",
        severity: "error",
        emailError: true,
      });
    }
  };*/

  const onCloseSnackbar = () => {
    setState({ ...state, openSnackbar: false });
  };

  return (
    <footer className="footer">
      <Container maxWidth="lg">
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="center"
          spacing={3}
          style={{ paddingTop: 32 }}
        >
          <Snackbar
            autoHideDuration={6000}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            open={state.openSnackbar}
            onClose={onCloseSnackbar}
          >
            <Alert severity={state.severity}>
              {state.snackbarMessage || ""}
            </Alert>
          </Snackbar>
          <ThemeProvider theme={theme}>
            <Hidden smUp>
              <Container maxWidth="lg">
                <Grid
                  container
                  direction="row"
                  // justify="space-between"
                  alignItems="center"
                  spacing={3}
                >
                  <Grid item xs={12}>
                    <Typography
                      variant="h6"
                      className={classes.bold}
                      style={{ textAlign: "center" }}
                    >
                      {"Abonează-te la Newsletter"}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      variant="subtitle1"
                      className={classes.text}
                      style={{ textAlign: "center" }}
                    >
                      {
                        "Pentru a fi la curent cu ultimile noutăți ale comunității noastre"
                      }
                    </Typography>
                  </Grid>
                  <form name="newsletter_mobile" method="POST" data-netlify="true" className={classes.form_mobile}>
                  <input type="hidden" name="form-name" value="newsletter_mobile" />
                  <Grid item>
                    <TextField
                      fullWidth
                      id="email-input"
                      key="email-input"
                      type="email"
                      name="email"
                      value={state.email || ""}
                      variant="outlined"
                      placeholder={"Adresa de Email"}
                      onChange={(e) =>
                        setState({ ...state, [e.target.name]: e.target.value })
                      }
                    />
                  </Grid>
                  <Grid item>
                    <Button
                      fullWidth
                      type="submit"
                      variant="contained"
                      className={classes.button}
                      //onClick={onClick}
                    >
                      {"Abonează-mă"}
                    </Button>
                  </Grid>
                  </form>
                  <Grid item xs={12}>
                    <Grid container justify="center">
                      <Grid item>
                        <Link href="https://www.facebook.com/MentorMD">
                          <IconButton>
                            <FacebookIcon
                              className={classes.icon}
                              fontSize="large"
                            />
                          </IconButton>
                        </Link>
                      </Grid>
                      <Grid item>
                        <Link href="https://www.youtube.com/channel/UCwVvehu5MUXjh6SFKCtvN-g">
                          <IconButton>
                            <YouTubeIcon
                              className={classes.icon}
                              fontSize="large"
                            />
                          </IconButton>
                        </Link>
                      </Grid>
                      <Grid item>
                        <Link href="https://www.instagram.com/mentorme_md/">
                          <IconButton>
                            <InstagramIcon
                              className={classes.icon}
                              fontSize="large"
                            />
                          </IconButton>
                        </Link>
                      </Grid>
                      <Grid item>
                        <Link href="https://www.linkedin.com/company/12984932">
                          <IconButton>
                            <LinkedInIcon
                              className={classes.icon}
                              fontSize="large"
                            />
                          </IconButton>
                        </Link>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Container>
            </Hidden>
            <Hidden xsDown>
              <Grid item xs={12}>
                <Typography variant="h6" className={classes.bold}>
                  {"Abonează-te la Newsletter"}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle1" className={classes.text}>
                  {
                    "Pentru a fi la curent cu ultimile noutăți ale comunității noastre"
                  }
                </Typography>
              </Grid>
              <form name="newsletter" method="POST" data-netlify="true" className={classes.form}>
              <input type="hidden" name="form-name" value="newsletter" />
              <Grid item>
                <TextField
                  fullWidth
                  id="email-input"
                  key="email-input"
                  type="email"
                  name="email"
                  value={state.email || ""}
                  variant="outlined"
                  placeholder={"Adresa de Email"}
                  onChange={(e) =>
                    setState({ ...state, [e.target.name]: e.target.value })
                  }
                  // error={state.emailError || false}
                />
              </Grid>
              <Grid item>
                <Button
                  fullWidth
                  type="submit"
                  variant="contained"
                  // color="primary"
                  //onClick={onClick}
                  className={classes.button}
                >
                  {"Abonează-mă"}
                </Button>
              </Grid>
              </form>
              <Grid item xs={12} style={{ paddingBottom: 32 }}>
                <Link href="https://www.facebook.com/MentorMD">
                  <IconButton>
                    <FacebookIcon className={classes.icon} fontSize="large" />
                  </IconButton>
                </Link>

                <Link href="https://www.youtube.com/channel/UCwVvehu5MUXjh6SFKCtvN-g">
                  <IconButton>
                    <YouTubeIcon className={classes.icon} fontSize="large" />
                  </IconButton>
                </Link>

                <Link href="https://www.instagram.com/mentorme_md/">
                  <IconButton>
                    <InstagramIcon className={classes.icon} fontSize="large" />
                  </IconButton>
                </Link>

                <Link href="https://www.linkedin.com/company/12984932">
                  <IconButton>
                    <LinkedInIcon className={classes.icon} fontSize="large" />
                  </IconButton>
                </Link>
              </Grid>
            </Hidden>
          </ThemeProvider>
        </Grid>
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
          spacing={1}
        >
          <Grid item>
            <Link href="https://welcome.mentorme.md/privacy-policy">
              <Typography
                variant="overline"
                display="block"
                gutterBottom
                className={classes.text}
              >
                {"Politica de confidențialitate"}
              </Typography>
            </Link>
          </Grid>
          <Grid item>
            <CopyrightIcon fontSize="small" style={{ color: "#fff" }} />
          </Grid>
          <Grid item>
            <Typography
              variant="overline"
              display="block"
              gutterBottom
              className={classes.text}
            >
              {`${state.curretYear.getFullYear()}, MentorMe`}
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </footer>
  );
}

export default Footer;
